/* New Date Picker React Suite */
/* https://rsuitejs.com/components/date-picker/ */

.rs-input {
  border-radius: inherit;
  font-family: 'Roboto';
}
.rs-input-group {
  border: 1px solid #afb0b1;
  border-radius: 4px;
}
.rs-input-group.rs-input-group-inside .rs-input-group-addon ~ input.rs-input {
  padding-right: 1.2rem;
  padding-left: 0.3rem;
}
.rs-picker-label.rs-input-group-addon {
  position: absolute;
  right: 1.6rem;
  z-index: 10;
}
.rs-input-group.rs-input-group-inside {
  position: relative;
}
.rs-input-group-sm.rs-input-group > .rs-input,
.rs-input-group-sm.rs-input-group > .rs-input-group-addon {
  padding-left: 0.8rem;
  background: none !important;
}
.rs-input-group .rs-form-control-wrapper ~ .rs-input-group-addon,
.rs-input-group input.rs-input ~ .rs-input-group-addon {
  padding: 4px 7px 4px 0;
  min-width: 2rem;
}
.rs-input-group:not(.rs-input-group-disabled):hover {
  border-width: 1px;
  border-color: #afb0b1;
}
.rs-picker-toggle-wrapper {
  border-radius: 4px;
  width: 15rem;
}
.rs-input-group-sm > .rs-input,
.rs-input-group.rs-input-group-sm > .rs-input-group-addon {
  font-size: 1rem;
}

/* Styles relative to popup that is rendered in the root of body element */
.rs-picker-popup-date {
  z-index: 2000 !important;
}
.rs-btn-primary,
.rs-btn-primary:focus,
.rs-btn-primary:hover,
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active,
.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
  background-color: #0044f0 !important;
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px #0044f0 !important;
}
.rs-calendar-time-view .rs-calendar-header-title-time,
.rs-calendar-month-view .rs-calendar-header-title-date,
.rs-calendar-month-dropdown-year-active {
  color: #0044f0 !important;
}
[class='rs-calendar-table-cell'] span,
[class='rs-calendar-month-dropdown-cell'] span,
[class='rs-calendar-time-dropdown-cell'] {
  color: #343434 !important;
}
