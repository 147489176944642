.dashboardCard {
  border-radius: 10px;
  font-weight: 600;
  margin-left: 3rem;
  font-size: 1rem;
  line-height: 1;
  height: max-content;
  display: flex;
  align-items: flex-end;
  line-height: 1.2;
  border-style: solid;
  color: #333333;
  background-color: #fff;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 1.4rem 1rem 1.4rem 0.5rem;
}

.dashboardCard > span {
  font-size: 2rem;
  line-height: 1;
  margin-right: 0.3rem;
  align-self: center;
}

.dashboardCard > .legend {
  font-size: 1rem;
  align-self: center;
  margin-left: 0.4rem;
}

.dashboardCard > .icon-wrapper {
  text-align: center;
  padding: 10px;
  width: 5rem;
  border-radius: 20px;
  margin-right: 0.1rem;
  line-height: 1;
}

.dashboardCard > .icon-wrapper > svg {
  font-size: 2.4rem;
}

.dashboardCardWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.dashboardMenuSticky {
  position: sticky;
  top: -32px;
  z-index: 100;
}

.k-dropdown-wrap,
.k-dropdown-wrap:hover {
  background-color: #fff !important;
}

.k-switch-on .k-switch-container {
  background-color: #0044f0 !important;
}
