@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  outline: none;
}

button:hover {
  outline: none;
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: none;
}
textarea:focus,
textarea:focus-visible {
  border-width: 2px;
  border-color: #2563eb !important;
}

.underline:hover {
  text-decoration: underline;
}

.homeBackground {
  background-color: #ffffff !important;
}

/* Kendo DateTimePicker */
.k-nav-today,
.k-datetime-container .k-time-now {
  display: none !important;
}

.k-calendar:focus-visible {
  outline: 1px solid transparent !important;
}

.k-calendar .k-calendar-td.k-state-selected .k-link,
.k-calendar .k-calendar-td.k-state-selected:hover .k-link,
.k-calendar .k-calendar-td.k-state-selected.k-state-hover .k-link {
  background-color: #0044f0;
}

.k-button.k-state-selected,
.k-button-secondary.k-state-selected,
.k-button-primary,
.k-button.k-primary,
.k-button-primary.k-state-hover,
.k-state-hover.k-button.k-primary,
.k-button-primary:hover,
.k-button.k-primary:hover {
  border-color: #0044f0;
  background-color: #0044f0;
}

.k-calendar .k-nav-today,
.k-calendar .k-nav-today:hover,
.k-calendar .k-nav-today.k-state-hover,
.k-calendar .k-nav-today:focus,
.k-calendar .k-nav-today.k-state-focus,
.k-time-list .k-item:hover,
.k-calendar .k-calendar-view .k-today {
  color: #0044f0;
}

.k-calendar .k-calendar-td.k-state-selected:hover .k-link,
.k-calendar .k-calendar-td.k-state-selected.k-state-hover .k-link,
.k-button-primary:hover,
.k-button.k-primary:hover,
.k-button-primary.k-state-hover,
.k-state-hover.k-button.k-primary,
.k-button.k-state-selected:hover,
.k-button-secondary.k-state-selected:hover .k-calendar .k-nav-today:hover,
.k-calendar .k-nav-today.k-state-hover,
.k-calendar .k-nav-today:focus,
.k-calendar .k-nav-today.k-state-focus {
  opacity: 0.7;
}

.k-calendar .k-content.k-scrollable,
.k-calendar .k-calendar-content.k-scrollable {
  margin-left: -117px;
}

.k-calendar-yearview .k-link {
  width: 47px;
  height: 47px;
}

.k-calendar-decadeview .k-link,
.k-calendar-centuryview .k-link {
  width: 50px;
}

/* Kendo Search Bar */
.k-autocomplete .k-clear-value {
  align-items: end;
}

.custom-dialog {
  z-index: 1200 !important;
}

.custom-dialog .k-dialog {
  max-width: 1440px;
  border-radius: 5px;
  padding: 1.5rem 1.5rem 0 1.5rem;
}

.custom-dialog .k-dialog-titlebar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.custom-dialog .k-dialog-content {
  padding: 0;
}

.k-editor-window .k-dialog-titlebar {
  border-color: inherit;
  color: #ffffff;
  background-color: #0044f0;
}

button.text,
button.text:focus-visible,
button.text:focus,
button.text:hover,
button.text:active,
button.text:visited {
  border: none !important;
}

button.text:focus-visible {
  outline: 2px solid transparent !important;
}

.k-switch {
  width: 43px;
}
.k-switch-container {
  width: auto;
  height: 1.6rem;
}
.k-switch-handle {
  height: 1.2rem;
  width: 1.2rem;
}
.k-switch-on .k-switch-handle {
  left: calc(100% - 22px);
  top: 0.18rem;
}
.k-switch-off .k-switch-handle {
  top: 0.18rem;
  left: 2px;
}
.k-switch.k-state-focused .k-switch-container {
  outline: none;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
}
.k-switch-on .k-switch-container {
  background-color: #1f9801 !important;
}
.k-switch-off .k-switch-container,
.k-switch-off:hover .k-switch-container {
  background-color: #c30000 !important;
}

.MuiTooltip-popper {
  /*
  top: -10px !important;
  */
  > div,
  > span,
  > p {
    font-size: 12px !important;
  }
}

/* DateTimePicker adjustment */
.k-time-header {
  padding-top: 0;
}

.k-datetime-footer {
  margin-top: 0;
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: #fff;
  z-index: 10003;
  padding-top: 12px;
}

.k-animation-container.k-animation-container-relative.k-reset.k-animation-container-shown {
  position: absolute;
}

.k-datetime-container .k-datetime-wrap {
  height: 303px;
}

.k-datetime-container .k-date-tab .k-datetime-buttongroup {
  padding-bottom: 0;
}

.k-calendar-infinite .k-calendar-header {
  padding-top: 5px;
  padding-bottom: 0;
}

.k-calendar-th {
  height: 25px;
}

.k-calendar-monthview .k-link,
.k-calendar-monthview .k-calendar-td {
  height: 26px;
}

.k-time-list .k-item,
.k-calendar-monthview .k-link {
  font-size: 14px;
}

.k-time-container > ul {
  transform: translateY(0) !important;
}

.k-calendar-th {
  font-size: 11px;
}

.k-time-header .k-title {
  font-size: 13.7px;
  margin-left: 17px;
}

.k-time-highlight {
  top: 15%;
}

.k-time-separator {
  align-self: start !important;
  margin-top: 24px;
}

.k-time-list .k-item {
  min-height: calc(1.42em + 2px);
  line-height: calc(1.42em + 2px);
}

.k-grid-header .k-header,
.k-grid-header-sticky {
  vertical-align: top !important;
}

.plusIcon {
  width: 1.5rem;
}

.k-datetime-container {
  z-index: 30000;
}

.custom-dialog .k-widget.k-window.k-dialog {
  max-height: 96vh;
}

.react-multiple-carousel__arrow {
  z-index: 10 !important;
}

@media (max-width: 1500px) {
  /* GRIDS */
  .gridToolbox .buttonLabel {
    display: none;
  }
  .toolboxButtonWrapper .MuiButton-startIcon {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 800px) and (max-width: 1440px) {
  * {
    font-size: 0.8vw;
  }
  #root {
    min-width: 100% !important;
  }
  .betaLabel {
    top: calc(0px + 0.1rem) !important;
    left: calc(-36px + 0.35rem) !important;
  }
  .mainMenuContent .icon {
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
    padding-right: 0.75rem !important;
  }
  .conferenceNameWrapper svg,
  .mainMenuContent .icon svg {
    width: 1.2rem !important;
  }
  .menuWrapper {
    max-width: 14.5rem !important;
  }
  .logoWrapper {
    margin-left: 1.5rem !important;
  }
  .logoWrapperConferences {
    margin-left: -0.5rem !important;
  }
  .logoWrapperConferences svg,
  .logoWrapper svg {
    width: 11rem !important;
  }
  .mainMenuContent .icon .caret {
    margin-right: 0.3rem;
  }
  .mainMenuContent .icon .caret svg {
    width: 0.3rem !important;
  }
  .mainMenuContent .labelSubItem {
    margin-left: 3.5rem !important;
  }
  .hasChildren .toggle svg {
    width: 0.9rem;
  }
  .mainContentImpersonation {
    height: calc(100% - 1.6rem) !important;
  }
  .trashCan {
    width: 1.4rem;
    height: 1.7rem;
  }
  .k-switch-on .k-switch-handle,
  .k-switch-off .k-switch-handle {
    top: 0;
  }
  .k-switch-on .k-switch-handle {
    left: calc(100% - 19px);
  }
  .k-switch-off .k-switch-handle {
    left: 1.5rem;
  }
  .k-switch-container {
    height: 1.7rem;
  }
  .k-switch {
    width: 3rem;
  }
  .k-switch-label-on {
    left: 2.3rem;
  }
  .k-switch-label-off {
    right: 2.3rem;
  }
  .k-pager-sizes .k-input {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .squaredAddButton svg {
    width: 2.5rem;
    height: 2.5rem;
  }

  .ressolutionMessageWrapper {
    display: none;
  }

  /* Checkbox */
  .k-checkbox:checked::before,
  .k-checkbox.k-checked::before {
    top: 50% !important;
    left: 50% !important;
    transform: scale(1) translate(-30%, -34%);
    font-size: 8px !important;
  }
  .checkbox {
    height: 2.7rem;
  }
  .checkbox [type='checkbox']:checked {
    transform: scale(0.6);
  }
  .checkbox .checkmark {
    transform: scale(0.6);
  }
  .checkbox label {
    margin: 0;
  }

  /* Label manage */
  .MuiInputAdornment-root svg {
    margin: 0 0 0.2rem 0 !important;
    width: 1.5rem !important;
  }
  .MuiTypography-body1 {
    font-size: 0.975rem !important;
  }
  .MuiListItemIcon-root {
    min-width: 0 !important;
  }

  /* GRIDS */
  .resizeWrapper {
    display: none;
  }
  .gridToolbox .k-menu-link {
    padding: 0.5rem 2rem;
  }
  .gridToolbox .k-i-more-vertical,
  .gridToolbox .MuiButton-iconSizeMedium > :first-child {
    font-size: 1.2rem;
  }
  .gridToolbox .MuiButton-startIcon {
    margin-left: 0;
    margin-right: 0;
  }
  .gridToolbox .MuiButton-label {
    align-items: center;
    justify-content: center;
  }
  .gridToolbox .MuiButton-root {
    min-width: 4rem;
    height: 3.3rem;
    padding: 1rem 1.1rem !important;
  }
  .gridToolbox .resizeWrapper button {
    height: 3.3rem;
    padding: 0.1rem 0.5rem !important;
  }
  .gridToolbox .resizeWrapper span {
    font-size: 1.3rem;
  }
  .gridToolbox .exportButton svg {
    width: 1.6rem;
    height: 1.6rem;
  }
  .gridToolbox .resizeWrapper {
    padding: 0 1rem !important;
    height: 3.3rem;
  }
  .gridToolbox .toolboxButtonWrapper {
    margin-left: 1rem;
  }
  .gridToolbox .MuiIconButton-root {
    padding: 0 1.2rem !important;
  }
  .gridToolbox .configsWrapper {
    margin-right: 0;
    height: 3.1rem;
    display: flex;
    align-items: center;
  }
  .gridToolbox .moreVerticalWrapper {
    padding-top: 0.8rem;
  }
  .gridToolbox > div {
    padding-bottom: 0;
  }
  .gridToolbox .increase {
    font-size: 2rem !important;
  }
  .gridToolbox .decrease {
    font-size: 2.7rem !important;
  }
  .gridToolbox .k-menu-link {
    padding: 0 0.5rem;
  }
  .myTableGridWrapper *,
  .gridWrapper * {
    font-size: 0.85rem;
    /* font-size: inherit; */
  }
  .myTableGridWrapper .k-grid td,
  .gridWrapper .k-grid td {
    padding: 3px 10px;
  }
  .myTableGridWrapper .k-pager-wrap,
  .gridWrapper .k-pager-wrap {
    padding: 0.3rem 0.2rem;
  }
  .myTableGridWrapper th.k-header > div > div,
  .gridWrapper th.k-header > div > div {
    top: 0.5rem !important;
  }
  .myTableGridWrapper .tooltip_icon span,
  .myTableGridWrapper th.k-header > div > div .k-i-more-vertical,
  .gridWrapper .tooltip_icon span,
  .gridWrapper th.k-header > div > div .k-i-more-vertical {
    font-size: 1.2rem;
  }
  .myTableGridWrapper .k-dropdown span.k-dropdown-wrap,
  .gridWrapper .k-dropdown span.k-dropdown-wrap {
    border: none;
  }
  .myTableGridWrapper .k-filtercell-wrapper,
  .gridWrapper .k-filtercell-wrapper {
    align-items: center;
  }
  .myTableGridWrapper .k-filtercell,
  .gridWrapper .k-filtercell {
    width: calc(100% - 0.5rem);
  }
  .myTableGridWrapper .k-dropdown-operator .k-dropdown-wrap .k-select,
  .gridWrapper .k-dropdown-operator .k-dropdown-wrap .k-select {
    width: calc(1.4285714286em + 0.65rem);
    height: calc(1.4285714286em + 0.77rem);
    font-size: 0.9rem;
  }
  .myTableGridWrapper span.k-dropdown-wrap,
  .gridWrapper span.k-dropdown-wrap {
    border-radius: 0 0.4rem 0.4rem 0 !important;
  }
  .myTableGridWrapper input.k-textbox,
  .gridWrapper input.k-textbox {
    padding: 0 0.5rem;
    height: 2.4rem;
    margin-top: 0.1rem;
  }
  .myTableGridWrapper .k-numerictextbox,
  .gridWrapper .k-numerictextbox {
    height: 2.2rem !important;
    margin-top: 0.1rem;
  }
  .myTableGridWrapper .k-grid-header .k-header > .k-link,
  .gridWrapper .k-grid-header .k-header > .k-link {
    padding: 1.3rem 1.6rem !important;
  }
  .myTableGridWrapper .k-filter-row th,
  .gridWrapper .k-filter-row th {
    padding: 0.4rem 0.8rem !important;
  }

  .myTableGridWrapper .k-filtercell .k-filtercell-wrapper > .k-textbox,
  .gridWrapper .k-filtercell .k-filtercell-wrapper > .k-textbox {
    margin: 0;
  }
  .myTableGridWrapper .k-button.k-button-icon,
  .gridWrapper .k-button.k-button-icon {
    width: calc(1.4285714286em + 1.1rem);
    height: calc(1.4285714286em + 1.1rem);
  }
  .myTableGridWrapper th.k-header,
  .gridWrapper th.k-header {
    padding: 0.5rem 1rem !important;
  }
  .myTableGridWrapper .k-textbox,
  .gridWrapper .k-textbox {
    border-top-left-radius: 0.4rem !important;
    border-bottom-left-radius: 0.4rem !important;
  }
  .myTableGridWrapper .k-numeric-wrap,
  .gridWrapper .k-numeric-wrap {
    border-top-left-radius: 0.4rem !important;
    border-bottom-left-radius: 0.4rem !important;
  }
  .MuiTooltip-popper > div,
  .MuiTooltip-popper > span,
  .MuiTooltip-popper > p {
    font-size: 1rem !important;
  }

  /* Profile Menu*/
  .menuProfile svg {
    width: 2.5rem;
  }
  /* Profile Menu*/
  .menuProfile svg {
    width: 2.5rem;
  }
  .profileMenu {
    top: 4.6rem !important;
  }

  /* DASHBOARD */
  .dashboardPage text {
    font-size: 0.8vw !important;
  }
  .submisisonsPerKeywordsChart {
    height: 45rem !important;
    overflow: hidden;
  }
  .submisisonsPerKeywordsChart > div {
    height: 65rem;
  }
  .submisisonsPerKeywordsChart .chartLabel {
    top: 40rem;
    bottom: inherit;
  }
  .submissionsPerDayChart,
  .submissionsPerStatusChart,
  .invitationsPerStatusChart,
  .assignmentsPerStatusChart,
  .assignmentsPerReviewerChart,
  .submissionsRatiosChart,
  .biddingPerTypeChart {
    height: 45rem !important;
    overflow: hidden;
  }

  /* Manage columns */
  .manageColumnsWrapper {
    min-width: inherit !important;
    height: 100% !important;
  }
  .manageColumnsWrapper .MuiDialog-paperFullWidth {
    max-height: 100%;
    margin: 0 !important;
  }
  .manageColumnsWrapper .MuiDialog-paperFullWidth {
    width: calc(100% - 6.4rem) !important;
  }
  .manageColumnsWrapper .k-listbox {
    height: 20rem !important;
    width: 100%;
  }
  .manageColumnsWrapper .k-listbox.k-listbox-toolbar-right .k-listbox-toolbar {
    margin-left: 0.6rem !important;
  }
  .manageColumnsWrapper .k-listbox-toolbar {
    transform: scale(0.6);
    height: max-content;
    transform-origin: top;
  }

  /* Color picker */
  .k-colorpicker .k-select {
    padding: 0 0.4rem;
  }
  .k-colorpalette {
    zoom: 0.3;
    max-height: 10% !important;
  }

  .k-animation-container:has(.k-colorpalette) {
    width: 26rem !important;
    height: 20rem;
    overflow: hidden;
  }

  /* Dialog */
  .MuiInputBase-input.MuiOutlinedInput-input {
    padding: 0.55rem !important;
  }
  #alert-dialog-title,
  .dialog-content {
    padding: 1rem 2rem !important;
    max-height: 89vh !important;
    max-width: 96vw !important;
  }
  .k-dialog-content .k-dropdown,
  .k-dialog-content .k-dropdowntree {
    width: 10.9em;
    height: 3.3rem;
  }
  .k-dialog-content .k-dropdown-wrap {
    border-radius: 0.5rem !important;
  }
  .MuiInputLabel-root {
    transform: scale(1.1) !important;
  }
  .MuiDialogTitle-root,
  .MuiDialogContent-root {
    padding: 1rem 2rem !important;
    overflow-x: hidden;
  }
  .htmlTextEditor,
  .k-dialog-content .k-editor {
    transform: scale(0.7);
    transform-origin: top left;
    width: 142%;
  }
  .editorWrapper {
    height: 60vh;
  }

  /* Manage labels */
  td.MuiTableCell-root,
  th.MuiTableCell-root {
    padding: 8px;
  }
  tr.MuiTableRow-root th.MuiTableCell-root span {
    padding: 3px 8px !important;
  }

  /* Date time picker */
  .k-datetime-container {
    transform: scale(0.5);
    transform-origin: top left;
    z-index: 30000;
  }
  .k-icon {
    font-size: 1.7rem;
  }
  .phaseAddDatePicker {
    left: 38% !important;
  }

  /* New Date Picker React Suite */
  /* https://rsuitejs.com/components/date-picker/ */
  .rs-input-group {
    border-radius: 0.3rem;
  }
  .rs-picker-popup-date {
    transform: scale(0.6);
    transform-origin: top left;
    z-index: 30000;
  }
  .rs-input-group-sm.rs-input-group > .rs-input,
  .rs-input-group-sm.rs-input-group > .rs-input-group-addon {
    height: 1.7rem;
  }
  .rs-input-group-sm > .rs-input,
  .rs-input-group.rs-input-group-sm > .rs-input-group-addon {
    font-size: 1rem;
  }
  .rs-input-group.rs-input-group-sm > .rs-input-group-addon {
    min-width: 3rem;
  }
  .rs-picker-label {
    right: 2.2rem !important;
  }

  /* Aggregate columns */
  .MuiDialog-container {
    height: 102vh !important;
    outline: 0;
    width: 100vw;
  }
  .customColumnsWrapper {
    align-items: center;
  }
  .editIcon {
    width: 1.4rem;
  }

  /* Submission detail */
  #paper-information header button,
  .tab-forms-wrapper header button {
    min-width: 12rem !important;
  }
  #authors button svg {
    width: 2.5rem;
    height: 2.5rem;
  }

  .MuiChip-root {
    height: 3rem !important;
  }
  .MuiChip-label {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .MuiChip-deleteIcon {
    width: 2rem !important;
    height: 2rem !important;
  }

  .arrowUpCircle,
  .arrowDownCircle {
    width: 2.2rem;
  }

  .emailIframe {
    -moz-transform: scale(0.81);
    -o-transform: scale(0.81);
    -webkit-transform: scale(0.81);
    zoom: 0.81;
    -moz-transform-origin: center center;
    -o-transform-origin: center center;
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }

  .custom-dialog .k-widget.k-window.k-dialog {
    max-height: 89vh;
  }

  .myTableGridWrapper .k-input {
    padding: 0 0.5rem;
    height: 2rem;
    width: 5rem;
  }
  .myTableGridWrapper a.k-select {
    padding: 0;
  }

  .k-calendar-container {
    transform: scale(0.5);
    transform-origin: 0 0;
  }

  .settingsIcon {
    width: 1.5rem;
  }

  .export-button svg,
  .expand-button svg,
  .viewNewIcon,
  .chevronDownButton,
  .chevronUpButton,
  .copyIcon,
  .closeIcon {
    width: 1.5rem;
  }
  .viewIcon {
    width: 1.7rem;
    height: auto;
    margin-right: 0.5rem;
  }
  .editIcon {
    height: auto;
    margin-right: 0.5rem;
  }

  .rct-tree-items-container .rct-tree-item-button,
  .rct-tree-item-title-container-selected .rct-tree-item-button::before {
    height: 3rem;
  }
  .rct-tree-item-button-isFolder {
    margin-bottom: 0.5rem !important;
  }

  /* FormBuilder */
  .MuiSwitch-root {
    transform: scale(0.5);
  }
  .visibilityButton {
    font-size: 0.8vw;
  }

  .footerToolboxBuilderCard {
    height: 3rem;
  }

  .react-multiple-carousel__arrow {
    min-width: 2.6rem;
    min-height: 2.6rem;
  }
  .react-multiple-carousel__arrow::before {
    font-size: 1.6rem;
  }

  .react-multi-carousel-item {
    width: 14.5rem !important;
  }
  .react-multi-carousel-item > div {
    max-width: 14rem !important;
  }

  .sidePanelWrapper {
    top: 4rem !important;
    height: calc(100% - 4rem) !important;
  }

  .phaseEventWrapper {
    max-width: 57.1rem !important;
  }

  .react-multi-carousel-item .plus-icon-wrapper {
    width: 2.4rem;
  }
  .react-multi-carousel-item .plus-icon-wrapper .plusIcon {
    width: 2.2rem;
    overflow: visible;
  }

  /* Phase event sidepanel */
  .sidePanelWrapper {
    width: 45.5rem !important;
    max-width: 45.5rem !important;
  }
  .form-element .k-datetimepicker {
    max-width: 30rem !important;
    min-width: inherit !important;
  }
  .form-element .k-switch {
    width: 3rem !important;
    margin-right: 0.5rem !important;
  }
  .form-element .k-switch-off .k-switch-handle {
    top: 0 !important;
  }
  .form-element .switch-wrapper div {
    font-size: 1rem !important;
  }
  .items-end .k-dropdown-wrap {
    height: max-content !important;
  }
  .form-element .k-dropdown,
  .items-end .k-dropdown {
    height: inherit !important;
  }
  .items-end .k-dropdown-wrap .k-select {
    height: max-content !important;
  }
  .panelContentWrapper .panelContent > div {
    padding: 2.3rem !important;
  }

  /* Settings Paper status */
  .paperStatusListWrapper li button,
  .paperStatusListWrapper li div {
    height: 3rem;
  }
  .paperStatusListWrapper li button,
  .paperStatusListWrapper li button .emptyWrapper {
    width: 7.5rem !important;
    padding: 0.625rem;
  }
  .paperStatusListWrapper li .formattedNameWrapper {
    align-items: center;
  }

  /* ProfileForm */
  .profilePlusWrapper {
    width: max-content !important;
  }
  .tooltipProfileForm {
    top: 1.3rem !important;
  }
  .tooltipOrcid {
    left: 5.2rem !important;
  }
  .tooltipScholar {
    left: 8.5rem !important;
  }
  .tooltipDblp {
    left: 5rem !important;
  }
  .profileFormLanguageWrapper .k-dropdown {
    width: 100% !important;
  }
  .profileFormLanguageWrapper .k-dropdown .k-dropdown-wrap {
    height: 2.6rem;
    padding-top: 0.1rem;
    padding-bottom: 0.2rem;
  }
  .profileFormLanguageWrapper .k-dropdown .k-dropdown-wrap .k-input .flex {
    align-items: center;
  }
  .profileFormLanguageWrapper .k-dropdown .k-dropdown-wrap .k-input .flex svg {
    width: 1.4rem;
  }

  .profileFormDatetimeWrapper .dropdown-wrapper .k-dropdown {
    width: 100% !important;
  }
  .profileFormDatetimeWrapper .dropdown-wrapper .k-dropdown .k-dropdown-wrap {
    height: 2.5rem;
    padding-top: 0.1rem;
    padding-bottom: 0;
  }
  .profileFormDatetimeWrapper .dropdown-wrapper .k-dropdown .k-input {
    padding: 0 0.5rem;
  }

  .profileFormTimezoneWrapper .labelSelect {
    margin-bottom: 4px;
  }
  .timezoneSelectWrapper > div:first-of-type {
    height: 2.9rem;
  }
  .timezoneSelectWrapper > div:first-of-type > div {
    height: 2.7rem;
  }
  .timezoneSelectWrapper > div:first-of-type > div > div:has(svg) {
    width: 3.8rem;
  }
  .timezoneSelectWrapper > div {
    min-height: 2.2rem;
    height: auto;
  }
  .timezoneSelectWrapper > div:last-of-type {
    margin-top: 0;
  }
  .securityFormContentWrapper {
    max-width: 24rem !important;
  }

  .keywordCategoryLine {
    top: 17px !important;
  }
  .cardRowInfo {
    align-items: center;
  }

  .cardRowInfo > div {
    padding: 0.35rem;
  }

  .cardRowInfo svg {
    width: 1.6rem;
    height: 1.7rem;
  }

  .supportFooter p,
  .supportFooter p a {
    font-size: 0.8rem;
  }

  .flexSidePanel {
    transform: translate(1.4rem, -1.5rem) !important;
    height: calc(100% + 1.5rem) !important;
  }

  .infoArea .htmlTextEditor {
    height: 142% !important;
  }
}

@media (max-width: 1200px) {
  /* Profile Menu*/
  .profileMenu {
    top: 4.9rem !important;
  }
}

@media (max-width: 1100px) {
  .betaLabel {
    top: calc(3px + 0.1rem) !important;
    left: calc(-34px + 0.35rem) !important;
  }
}

@media (max-width: 1024px) {
  /* Profile Menu*/
  .profileMenu {
    top: 5.4rem !important;
  }
}

@media (max-width: 900px) {
  .betaLabel {
    top: calc(5px + 0.1rem) !important;
    left: calc(-32px + 0.35rem) !important;
  }
}

@media (max-width: 800px) {
  .-left-col {
    display: none;
  }
  #root {
    min-width: inherit !important;
  }
  .ressolutionMessageWrapper {
    background-color: rgba(51, 51, 51, 0.6);
    width: 100%;
    height: 100%;
  }
  .ressolutionMessage {
    max-width: 20rem !important;
    padding: 4rem 1.6rem !important;
  }
  .ressolutionMessage .content-wrapper .title,
  .ressolutionMessage .content-wrapper .title span {
    font-size: 1.2rem !important;
  }
  .ressolutionMessage .content-wrapper .text {
    margin-bottom: 0 !important;
    line-height: 1.5rem !important;
    font-size: 0.9rem !important;
  }
}
